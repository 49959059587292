<template>
  <div class="mine">
    <mt-header :title="$t('mine')">
      <call-phone slot="right" />
    </mt-header>
    <div class="user">
      <img src="../assets/ic_launcher.png" class="userIcon">
      <div class="title">{{ user.name || '---' }}</div>
      <div class="phone">{{ user.phone }}</div>
    </div>
    <div class="content">
      <mt-cell :title="$t('repayment')" is-link to="/repayment">
        <img class="content-icon" slot="icon" src="../assets/repayment.png" width="16" style="margin-right: 10px;">
      </mt-cell>
      <mt-cell :title="$t('orderHistory')" is-link to="/mine/orderHistory">
        <img class="content-icon" slot="icon" src="../assets/historicalOrders.png" width="16" style="margin-right: 10px;">
      </mt-cell>
      <!-- <mt-cell :title="$t('bankCard')" is-link to="/mine/bankCard">
        <img slot="icon" src="../assets/bankCard.png" width="18" style="margin-right: 8px;">
      </mt-cell>
      <mt-cell :title="$t('inviteFriends')" is-link to="/mine/inviteFriends">
        <img slot="icon" src="../assets/inviteFriends.png" width="20" style="margin-right: 6px;">
      </mt-cell>
      <mt-cell :title="$t('discountCoupon')" is-link to="/mine/discountCoupon">
        <img slot="icon" src="../assets/coupon.png" width="19" style="margin-right: 7px;">
      </mt-cell>
      <mt-cell :title="$t('myPromotion')" is-link to="/mine/myPromotion">
        <img slot="icon" src="../assets/myPromotion.png" width="19" style="margin-right: 7px;">
      </mt-cell> -->
      <!-- <mt-cell :title="$t('aboutUs')" is-link to="/mine/aboutUs">
        <img slot="icon" src="../assets/aboutUs.png" width="17" style="margin-right: 9px;">
      </mt-cell>
      <mt-cell :title="$t('help')" is-link to="/mine/help">
        <img slot="icon" src="../assets/help.png" width="17" style="margin-right: 9px;">
      </mt-cell> -->
    </div>
    <div class="bottom">
      <mt-button type="danger" size="large" @click="onLogOut">{{ $t('logOut') }}</mt-button>
    </div>
    <tab-bar />
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import tabBar from '@/components/tabBar.vue'
import { logout } from '../utils/api'
import { mapState, mapMutations } from "vuex";
export default {
  name: 'Mine',
  components: {
    tabBar,
    callPhone
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["SET_ACCESS_TOKEN", "SET_USER"]),
    async onLogOut() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.$axios({
        url: logout,
        method: 'DELETE',
      })
        .then(() => {})
        .catch(() => {})
      this.$indicator.close();
      this.$NProgress.done()
      this.SET_ACCESS_TOKEN();
      this.SET_USER({});
      this.$router.push('/user/login');
    }
  }
}
</script>

<style lang="scss" scoped>
.mine {
  background: url("../assets/confirm-bg.png")  no-repeat;
  background-size: 100% auto;
  min-height: 500px;
  .user {
    text-align: center;
    padding: 25px;
    .userIcon {
      width: 54px;
    }
    .title {
      color: white;
      font-size: 15px;
      margin-bottom: 6px;
    }
    .phone {
      color: white;
      font-size: 13px;
    }
  }
  > .content {
    margin: 30px 20px 10px 20px;
    .content-icon{
      height: 30px;
      width: auto;
    }
    .mint-cell {
      background: #F2EEFD;
      height: 75px;
      margin: 10px 20px 10px 20px;
      border-radius: 20px;
      font-size: 18px;
      line-height: 75px;
      &:last-child {
      }
      &:first-child {
        /deep/ .mint-cell-wrapper {
          background-image: none;
        }
      }
      /deep/ .mint-cell-title{
        margin-left: 20px;
      }
      /deep/ .mint-cell-text {
        font-size: 18px;
        line-height: 75px;
        color: #757575;
      }
    }
    /deep/ .mint-cell-wrapper {
      padding-left: 0px;
    }
  }
  .bottom {
    padding: 20px 33px;
    .mint-button {
      background: linear-gradient(to bottom, #BB97FA, #855EF4);
      height: 56px;
      border-radius: 30px;
    }
  }
}
</style>
